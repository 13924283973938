import React, { CSSProperties, FC, HTMLAttributes } from 'react'
import { theme } from '../theme'

export type BorderFrameProps = {
  width?: string | number
  borderStyle?: string
  color?: string
  transitionOff?: boolean
  transitionDuration?: string
  transitionEasing?: string
  transitionDelay?: string
} & HTMLAttributes<HTMLDivElement>

const BorderFrame: FC<BorderFrameProps> = ({
  width = 1,
  borderStyle = 'solid',
  color = theme.palette.primary.main,
  transitionOff = false,
  transitionDuration = '200ms',
  transitionEasing = 'ease',
  transitionDelay = 'none',
  children,
  ...rest
}) => {
  const transition = transitionOff
    ? {}
    : {
        transitionDelay,
        transitionDuration,
        transitionTimingFunction: transitionEasing,
        transitionProperty: 'border',
      }
  return (
    <div
      className="border-frame"
      {...rest}
      style={
        {
          position: 'relative',
          ...rest?.style,
        } as CSSProperties
      }
    >
      <div
        className="border-frame-top"
        style={
          {
            zIndex: 1,
            width: '100%',
            position: 'absolute',
            top: 0,
            borderTopWidth: width,
            borderTopColor: color,
            borderTopStyle: borderStyle,
            ...transition,
          } as CSSProperties
        }
      />
      <div
        className="border-frame-bottom"
        style={
          {
            zIndex: 1,
            width: '100%',
            position: 'absolute',
            bottom: 0,
            borderBottomWidth: width,
            borderBottomColor: color,
            borderBottomStyle: borderStyle,
            ...transition,
          } as CSSProperties
        }
      />
      <div
        className="border-frame-left"
        style={
          {
            zIndex: 1,
            height: '100%',
            position: 'absolute',
            left: 0,
            borderLeftWidth: width,
            borderLeftColor: color,
            borderLeftStyle: borderStyle,
            ...transition,
          } as CSSProperties
        }
      />
      <div
        className="border-frame-right"
        style={
          {
            zIndex: 1,
            height: '100%',
            position: 'absolute',
            right: 0,
            borderRightWidth: width,
            borderRightColor: color,
            borderRightStyle: borderStyle,
            ...transition,
          } as CSSProperties
        }
      />
      {children}
    </div>
  )
}

export default BorderFrame
