import { Video } from '@tvi/types/video'
import { IdType, StatusResponse } from '../..'
import TviApiService from '../../TviApiService'

export type GetVideoResponse = Video

export type InitiateUploadResponse = {
  uploadUri: string
  gcsUri: string
}

export type UpdateVideoResponse = Video

export interface VideoEndpoints {
  getVideo({ id }: IdType): Promise<GetVideoResponse>
  deleteVideo({ id }: IdType): Promise<StatusResponse>
  updateVideo({ video }: { video: Video }): Promise<UpdateVideoResponse>
  createVideo({
    projectId,
    video,
  }: {
    projectId: number
    video: Video
  }): Promise<Video>
  initiateUpload(): Promise<InitiateUploadResponse>
}

export default class VideoService
  extends TviApiService
  implements VideoEndpoints
{
  async getVideo({ id }: IdType): Promise<GetVideoResponse> {
    return this.request({
      method: 'GET',
      url: this.url(`/videos/${id}`),
    }).then(this.takeOk)
  }

  async createVideo({
    projectId,
    video,
  }: {
    projectId: number
    video: Video
  }): Promise<Video> {
    return this.request({
      method: 'POST',
      url: this.url(`/projects/${projectId}/videos/upload`),
      body: { video },
    }).then(this.takeOk)
  }

  async updateVideo({ video }: { video: Video }): Promise<UpdateVideoResponse> {
    return this.request({
      method: 'PUT',
      url: this.url(`/videos/${video.id}`),
      body: { video },
    }).then(this.takeOk)
  }

  async deleteVideo({ id }: IdType): Promise<StatusResponse> {
    return this.request({
      method: 'DELETE',
      url: this.url(`/videos/${id}`),
    }).then(this.takeOk)
  }

  async initiateUpload(): Promise<InitiateUploadResponse> {
    return this.request({
      method: 'POST',
      url: this.url(`/videos/initiate_upload`),
      body: {},
    }).then(this.takeOk)
  }
}
